import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalOpts } from '@shared/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public additionalMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOpts,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.initialValue !== undefined) {
      this.additionalMessage = this.data.initialValue;
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    if (this.data.additionalMessage) {
      this.dialogRef.close(this.additionalMessage);
    } else {
      this.dialogRef.close(true);
    }
  }
}
