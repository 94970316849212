import { ROLES_KEYS, UserProcessStatus } from '@interfaces';

export interface MenuItem {
  getRouterLink: (userProcessId?: string) => string[];
  mainLabelKey: string;
  svgIcon?: string;
  class?: string;
  isDisabled?: (userProcessStatus?: UserProcessStatus) => boolean;
  showOnMobile?: boolean;
  disabledItemTooltip?: string;
}

export const sideMenuConfig: { [key in ROLES_KEYS]: MenuItem[] } = {
  [ROLES_KEYS.Admin]: [
    {
      mainLabelKey: 'MANAGEMENT.MENU.APPLICATIONS-MANAGEMENT',
      getRouterLink: () => ['/management/applications-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.SCHEDULED_VISITS',
      getRouterLink: () => ['/management/visits-list'],
      class: 'visits-list',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VISITS_CALENDAR',
      getRouterLink: () => ['/management/visits-calendar'],
      class: 'visits-calendar',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.FOREIGNERS',
      getRouterLink: () => ['/management/foreigners-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.COMPANIES',
      getRouterLink: () => ['/management/companies-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.EMPLOYEES',
      getRouterLink: () => ['/management/employees-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.OFFICES',
      getRouterLink: () => ['/management/offices-list'],
      isDisabled: () => false,
      showOnMobile: false,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VOIVODESHIPS',
      getRouterLink: () => ['/management/voivodeships-list'],
      isDisabled: () => false,
      showOnMobile: false,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.DOCUMENTS-STATEMENTS',
      getRouterLink: () => ['/management/documents-statements'],
      isDisabled: () => false,
      showOnMobile: false,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.PARTNERS_AND_PAYOUTS',
      getRouterLink: () => ['/partners'],
      isDisabled: () => false,
      showOnMobile: true,
    },
  ],
  [ROLES_KEYS.Employee]: [
    {
      mainLabelKey: 'MANAGEMENT.MENU.APPLICATIONS-MANAGEMENT',
      getRouterLink: () => ['/management/applications-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.SCHEDULED_VISITS',
      getRouterLink: () => ['/management/visits-list'],
      class: 'visits-list',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VISITS_CALENDAR',
      getRouterLink: () => ['/management/visits-calendar'],
      class: 'visits-calendar',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.FOREIGNERS',
      getRouterLink: () => ['/management/foreigners-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.COMPANIES',
      getRouterLink: () => ['/management/companies-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VOIVODESHIPS',
      getRouterLink: () => ['/management/voivodeships-list'],
      isDisabled: () => false,
      showOnMobile: false,
    },
  ],
  [ROLES_KEYS.Team_Leader]: [
    {
      mainLabelKey: 'MANAGEMENT.MENU.APPLICATIONS-MANAGEMENT',
      getRouterLink: () => ['/management/applications-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.SCHEDULED_VISITS',
      getRouterLink: () => ['/management/visits-list'],
      class: 'visits-list',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VISITS_CALENDAR',
      getRouterLink: () => ['/management/visits-calendar'],
      class: 'visits-calendar',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.FOREIGNERS',
      getRouterLink: () => ['/management/foreigners-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.COMPANIES',
      getRouterLink: () => ['/management/companies-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.EMPLOYEES',
      getRouterLink: () => ['/management/employees-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VOIVODESHIPS',
      getRouterLink: () => ['/management/voivodeships-list'],
      isDisabled: () => false,
      showOnMobile: false,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.PARTNERS_AND_PAYOUTS',
      getRouterLink: () => ['/partners'],
      isDisabled: () => false,
      showOnMobile: true,
    },
  ],
  [ROLES_KEYS.Field_Consultant]: [
    {
      mainLabelKey: 'MANAGEMENT.MENU.APPLICATIONS-MANAGEMENT',
      getRouterLink: () => ['/management/applications-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.SCHEDULED_VISITS',
      getRouterLink: () => ['/management/visits-list'],
      class: 'visits-list',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.VISITS_CALENDAR',
      getRouterLink: () => ['/management/visits-calendar'],
      class: 'visits-calendar',
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'MANAGEMENT.MENU.FOREIGNERS',
      getRouterLink: () => ['/management/foreigners-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
  ],
  [ROLES_KEYS.Foreigner]: [
    {
      mainLabelKey: 'MENU.PRE-SURVEY',
      getRouterLink: (userProcessId: string) => {
        return userProcessId?.length ? ['/user-process', userProcessId, 'pre-survey'] : ['/purchase'];
      },
      svgIcon: 'datafile',
      class: 'pre-survey',
      isDisabled: userProcessStatus => {
        if (!userProcessStatus) {
          return true;
        }

        const allowedStatuses = [UserProcessStatus.FILLING_PRE_SURVEY, UserProcessStatus.PRE_SURVEY_VERIFICATION];
        return !allowedStatuses.includes(userProcessStatus);
      },
      showOnMobile: true,
      disabledItemTooltip: 'MENU.DISABLED_ITEM_TOOLTIP',
    },
    {
      mainLabelKey: 'MENU.USER-DETAILS',
      getRouterLink: (userProcessId: string) => {
        return userProcessId?.length ? ['/user-process', userProcessId, 'personal-details'] : ['/purchase'];
      },
      svgIcon: 'person',
      class: 'foreigner-details',
      isDisabled: userProcessStatus => {
        if (!userProcessStatus) {
          return true;
        }
        return ![
          UserProcessStatus.FILLING_DETAILS,
          UserProcessStatus.COMPLETING_DOCUMENTS,
          UserProcessStatus.CHANGES_REQUESTED,
        ].includes(userProcessStatus);
      },
      showOnMobile: true,
      disabledItemTooltip: 'MENU.DISABLED_ITEM_TOOLTIP',
    },
    {
      mainLabelKey: 'MENU.DOCUMENTS',
      getRouterLink: (userProcessId: string) => {
        return userProcessId?.length ? ['/user-process', userProcessId, 'documents'] : ['/purchase'];
      },
      svgIcon: 'document',
      class: 'foreigner-documents',
      isDisabled: userProcessStatus => {
        if (!userProcessStatus) {
          return true;
        }
        return ![
          UserProcessStatus.COMPLETING_DOCUMENTS,
          UserProcessStatus.CHANGES_REQUESTED,
          UserProcessStatus.READY_FOR_REVIEW,
        ].includes(userProcessStatus);
      },
      showOnMobile: true,
      disabledItemTooltip: 'MENU.DISABLED_ITEM_TOOLTIP',
    },
    {
      mainLabelKey: 'MENU.APPLICATION_PROCESSING',
      getRouterLink: (userProcessId: string) => {
        return userProcessId?.length ? ['/user-process', userProcessId, 'summary'] : ['/purchase'];
      },
      svgIcon: 'factcheck',
      class: 'foreigner-application-processing',
      isDisabled: userProcessStatus => {
        if (!userProcessStatus) {
          return true;
        }
        const allowedStatuses = [
          UserProcessStatus.REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE,
          UserProcessStatus.REVIEWED_READY_TO_PRINT,
          UserProcessStatus.OFFICE_VISIT_SCHEDULED,
          UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
          UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE,
          UserProcessStatus.APPLICATION_SUBMITTED,
          UserProcessStatus.DECISION_ISSUED,
          UserProcessStatus.ARCHIVED,
        ];
        return !allowedStatuses.includes(userProcessStatus);
      },
      showOnMobile: true,
      disabledItemTooltip: 'MENU.DISABLED_ITEM_TOOLTIP',
    },
    {
      mainLabelKey: 'MENU.ARTICLES',
      getRouterLink: () => ['/articles'],
      isDisabled: () => false,
      showOnMobile: true,
      svgIcon: 'document',
    },
  ],
  [ROLES_KEYS.Employer]: [
    {
      mainLabelKey: 'NT.MENU.STAY_LEGALISATION',
      getRouterLink: () => ['/manager/company-employees-list'],
      isDisabled: () => false,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'NT.MENU.CANDIDATE_VERIFICATION',
      getRouterLink: () => ['/manager/company-employees-list'],
      isDisabled: () => true,
      showOnMobile: true,
    },
    {
      mainLabelKey: 'NT.MENU.TRACKING',
      getRouterLink: () => ['/manager/company-employees-list'],
      isDisabled: () => true,
      showOnMobile: true,
    },
  ],
};
