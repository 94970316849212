import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User, UserProcess } from '@interfaces';
import { PurchaseTags } from '@shared/purchase-tags/service-level-tag.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-application-selector',
  templateUrl: './application-selector.component.html',
  styleUrls: ['./application-selector.component.scss'],
})
export class ApplicationSelectorComponent {
  @Input() myProcesses$: Observable<UserProcess[]>;
  @Input() myProcess: UserProcess;
  @Input() addingNewProcess: null | { id: string } = null;
  @Input() myself$: Observable<User>;

  @Output() applicationChanged: EventEmitter<any> = new EventEmitter<any>();

  public PurchaseTags = PurchaseTags;

  constructor() {}

  public compareSelectedApplication(app1: UserProcess, app2: UserProcess): boolean {
    return app1?.id === app2?.id;
  }
}
