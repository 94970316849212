import { Language } from './language';
import { MultiLangField } from './multi-lang-field';
import { Voivodeship } from './voivodeship';

export interface Article {
  id: string;
  isActive: boolean;
  title: MultiLangField;
  hasVoivodeshipIsolation: boolean;
  contents: ArticleContent[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ArticleContent {
  id: string;
  articleId: string;
  languageId: string;
  voivodeshipId?: string | null;
  language?: Partial<Language>;
  voivodeship?: Partial<Voivodeship>;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UploadFileEventOpts {
  file: File;
  languageId: string;
}

export interface AddArticleOpts {
  title: MultiLangField;
  isActive: boolean;
  hasVoivodeshipIsolation: boolean;
}
export interface EditArticleOpts extends Partial<AddArticleOpts> {
  articleId: string;
}

export interface GetArticleContentDataOpts {
  articleId: string;
  articleContentId: string;
}

export interface UpsertArticleContentOpts {
  articleId: string;
  languageId: string;
  voivodeshipId?: string;
  file: File;
}
