import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ReplaySubject } from 'rxjs';
import { AllDeviceInfo } from '@interfaces';
import { takeUntil } from 'rxjs/operators';

export interface ModalOpts {
  confirmationTranslated?: string;
  confirmationToTranslate?: string;
  translateParams?: { [key: string]: any };
  yesButtonKey?: string;
  noButtonKey?: string;
  additionalMessage?: boolean;
  initialValue?: any;
  autofocus?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public deviceInfo: AllDeviceInfo;
  private dialogWidth = '500px';

  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
      if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
        this.dialogWidth = '95vw';
      }
    });

    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      this.dialogWidth = '95vw';
    }
  }

  public open(opts: ModalOpts): MatDialogRef<ConfirmationModalComponent> {
    return this.dialog.open(ConfirmationModalComponent, {
      width: this.dialogWidth,
      maxWidth: this.dialogWidth,
      panelClass: ['confirmation-modal'],
      data: { ...opts },
      autoFocus: opts.autofocus || false,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
