import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AllDeviceInfo, UserProcess } from '@interfaces';
import { MenuItem } from '@layout/side-menu.config';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  @Input() sideMenuItems: MenuItem[];
  @Input() isForeigner: boolean;
  @Input() myProcess: UserProcess;
  @Input() addingNewProcess: null | { id: string } = null;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public deviceInfo: AllDeviceInfo;

  constructor(private readonly deviceInfoService: DeviceInfoService) {}

  ngOnInit() {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
