import { LocalStorage } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxMaskModule } from 'ngx-mask';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { environment } from '@environment';
import { effects, facades, reducers } from '@state';
import { CustomSerializer } from '@state/router';
import * as authActions from '@state/user-auth/user-auth.actions';

import { CoreModule } from '@core/core.module';
import { AllCompaniesGuard } from '@core/guards/all-companies.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { DocumentsGuard } from '@core/guards/documents.guard';
import { ForeignerDetailsGuard } from '@core/guards/foreigner-details.guard';
import { LanguagesGuard } from '@core/guards/languages.guard';
import { ProcessesGuard } from '@core/guards/processes.guard';
import { RequiredDataGuard } from '@core/guards/required-data.guard';
import { UserFormGuard } from '@core/guards/user-form.guard';
import { VoivodeshipsGuard } from '@core/guards/voivodeships.guard';
import { IdleStorage } from '@core/idle-storage';

import { fullDateFormat } from '@constants';
import { LayoutComponent } from '@layout/layout.component';
import { CookieBannerComponent } from '@shared/cookie-banner/cookie-banner.component';
import { LoadingAnimationComponent } from '@shared/loading-animation/loading-animation.component';
import { CustomMatPaginatorIntl } from '@shared/mat-paginator-intl';
import { SharedModule } from '@shared/shared.module';

import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { UserProcessFormComponent } from './user-process-form/user-process-form.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

import { ApplicationSelectorComponent } from '@layout/application-selector/application-selector.component';
import { SidebarMenuComponent } from '@layout/sidebar-menu/sidebar-menu.component';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';

import { IsForeignerGuard } from '@core/guards/is-foreigner.guard';
import { AccountDeletedComponent } from './account-deleted/account-deleted.component';
import { ChatDesktopComponent } from './chat/chat-desktop/chat-desktop.component';
import { ChatMobileButtonComponent } from './chat/chat-mobile-button/chat-mobile-button.component';
import { ChatMobileComponent } from './chat/chat-mobile/chat-mobile.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { NoItemsComponent } from './no-items/no-items.component';

const config: SocketIoConfig = {
  url: environment.SOCKET_URL,
  options: {
    // transports: ['polling'],
    // upgrade: false,
    transports: ['websocket'],
    withCredentials: true,
    autoConnect: false,
  },
};

registerLocaleData(localePL);

const components = [
  AppComponent,
  LoginComponent,
  LayoutComponent,
  UserSettingsComponent,
  ActivateAccountComponent,
  ResetPasswordComponent,
  UserProcessFormComponent,
  CookieBannerComponent,
  NotificationsComponent,
  LoadingAnimationComponent,
  ApplicationSelectorComponent,
  SidebarMenuComponent,
  ChatMobileComponent,
  ChatDesktopComponent,
  ChatMobileButtonComponent,
  NewAccountComponent,
  AccountDeletedComponent,
  NoItemsComponent,
];

const guards = [
  AuthGuard,
  AllCompaniesGuard,
  UserFormGuard,
  LanguagesGuard,
  VoivodeshipsGuard,
  RequiredDataGuard,
  DocumentsGuard,
  ForeignerDetailsGuard,
  ProcessesGuard,
  IsForeignerGuard,
];

export function clearState(reducer: any): any {
  return (state: any, action: any) => {
    if (action.type === authActions.logout.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const MY_FORMATS = {
  parse: {
    dateInput: fullDateFormat,
  },
  display: {
    dateInput: fullDateFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [...components],
  imports: [
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    SharedModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    ...guards,
    ...facades,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () => appInitService.ensureLangsLoaded(),
      deps: [AppInitService],
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: LocalStorage, useClass: IdleStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
